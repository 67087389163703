import Vue from 'vue'
import Vuex from 'vuex'
import http from '../public/axios'
import api from '../public/api.js'
import { whiteList } from '../router/util.js'

Vue.use(Vuex)

// 初始user
const user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : {}

export default new Vuex.Store({
    state:{
        rootLoading: true,
        pagesLoading: true,
        user: {
            ...user,
            inWhiteList: whiteList.includes(user.phone) || user.userType == 1,
            userExtend: user.userExtend ? user.userExtend.split(',') : []
        },
        token:window.localStorage.getItem('token') ?  window.localStorage.getItem('token'): '',
        isAdmin:false,
        isLogin:false,
        isGetRole: false, // 是否获取动态路由
        clientLoginRef: null,
        // 标签数据
        labelOptions: {
            institutionalBackgroundsOptions: [],
            focusAreasOptions: [],
            preferenceRoundOptions: [],
            industryOptions: [],
            businessIncomeOptions: [],
            netProfitOptions: []
        },
        // 省市
        labelGeoLogyOptions: []
    },
    mutations:{
        rootProcess(state,val){
            state.rootLoading = val;
        },
        pagesProcess(state,val){
            state.pagesLoading = val;
        },
        setUser(state,data){
            state.user = {
                ...data,
                inWhiteList: whiteList.includes(data.phone) || user.userType == 1,
                userExtend: user.userExtend ? user.userExtend.split(',') : []
            };
        },
        setToken(state,token){
            state.token = token;
        },
        setAdmin(state,isAdmin){
            state.isAdmin = isAdmin;
        },
        isGetRole(state,isGetRole){
            state.isGetRole = isGetRole;
        },
        login(state) {
            state.login = true
        },
        setClientLoginRef(state, val) {
            state.clientLoginRef = val
        },
        setLabelOptions(state, data) {
            state.labelOptions[data.key] = data.list
        },
        setLabelGeoLogyOptions(state, data) {
            state.labelGeoLogyOptions = data
        }

    },
    actions:{
        async getUserInfo({ commit }) {
            let result
            try {
            result = await http.Get(api.userInfo)
            console.log('用户信息',result)
            console.log('用户信息333',result?.data.userType == 1)
            // const rules = await getUserRoleIds()
            // // const YxgStatus = await getYxgStatus()
            // const admin = await getUserIdentity()
            // const sensitive = await getUserApplyStatus() // 敏感授权
            // 如果是管理员需判断是否是开启第三方授权
            //  // 首次进入 判断用户是否进行敏感信息授权 已授权不弹窗  不授权 且无记录变量 弹出提示框
            commit('setAdmin', result?.data.userType == 1)
            // commit('SET_ISADMIN', admin.data.admin && !info.data)
            // commit('SET_SENSITIVRACCREDIT', sensitive.data)
            // commit('SET_USER_IDENTITY', admin.data)
            // commit('SET_USERINFO', result.data)
            commit('isGetRole',true)
            } catch (err) {
                console.error('errorerror', err)
            }
            return {
                ...result?.data,
            }
        },
    }
})