<template>
    <el-dialog
        :title="current === 'loginComponent' ? '登录' : '注册'"
        append-to-body
        :show-close="showClose"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :custom-class="'clientLogin'"
        :visible.sync="dialogVisible"
        width="500px"
    >
    <div class="boday">
        <loginComponent v-if="current === 'loginComponent'" @success="loginSuccess" @registry="registry"/>
        <registryComponent v-if="current === 'registryComponent'" @success="registrysuccess" @login="login"/>
    </div>
    </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import loginComponent from '@/pages/login/loginComponent.vue'
import registryComponent from '@/pages/registry/registryComponent.vue'
const promise = {}
export default {
    name: "clientLogin",
    data() {
        return {
            dialogVisible: false,
            current: 'loginComponent',
            showClose: true
        }
    },
    components: {
        loginComponent,
        registryComponent
    },
    watch: {
        '$route.path'() {
            this.dialogVisible = false
        }
    },
    computed: {
        ...mapState(['user', 'token'])
    },
    methods: {
        init({ showClose = true } = {}) {
            return new Promise((resolve, reject) => {
                if (this.token) return resolve()
                promise.resolve = resolve
                promise.reject = reject
                this.$nextTick(() => {
                    this.showClose = showClose
                    this.dialogVisible = true
                })
            })
        },
        login() {
            this.current = 'loginComponent'
        },
        registry() {
            this.current = 'registryComponent'
        },
        loginSuccess(data) {
            this.dialogVisible = false
            promise.resolve(data)
        },
        registrysuccess(data) {
            this.dialogVisible = false
            promise.resolve(data)
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.clientLogin{
    border-radius: 10px;
    overflow: hidden;
}
</style>