export const http = '/';
export const baseRequestUrl = 'https://www.fundrelation.com'

export default {
    // 登录
    login: http + 'api/v1/open/user/login',
    // 验证码登录
    mobileLogin: http + 'api/v1/open/user/login/mobile',
    // 项目列表
    projectList: http + 'api/v1/investProject/listByPage',
    // 新建项目
    addProject: http + 'api/v1/investProject/add',
    // 注册查询标签组下的全部标签
    listByGroupId: http + 'api/v1/open/label/listByGroupId',
    //登录验证码
    loginSendValidCode: http + 'api/v1/open/sms/login/sendValidCode',
    //注册验证码
    registrySendValidCode: http + 'api/v1/open/sms/register/sendValidCode',
    // 注册界面上传文件接口
    uploadFile: http + 'api/v1/open/file/upload',
    // 注册方法
    registry: http + 'api/v1/open/register',
    // 获取地区的省市区信息
    getAddressList: http + 'api/v1/geology/geologyTree',
    // 获取地区的省市区信息
    geologyTree: http + 'api/v1/open/geologyTree',
    // 系统内标签列表
    // 无权限获取标签 1、行业标签  2、最近财年应收规模  3、最近财年利润规模 4.机构教育背景  5、关注领域 6、偏好轮次
    insideListByGroupId: http + 'api/v1/label/listByGroupId',
    // 系统内的文件上传接口
    insideUploadFile: http + 'api/v1/file/upload',
    // 上传附件接口
    uploadAttachment: http + 'api/v1/investProject/uploadAttachment',
    // 项目详情
    projectDetail: http + 'api/v1/investProject/getInfoById',
    // 获取各附件数量
    getAttachmentCount: http + 'api/v1/front/investProject/getProjectAttachmentCount',
    // 根据条件查询项目基本信息
    getProjectInfoByCondition: http + 'api/v1/front/investProject/listSimpleInfo',
    // 项目审核列表
    listApprovingProjectByPage: http + 'api/v1/investProject/listApprovingProjectByPage',
    // 附件审核列表
    listApprovingAttachmentByPage: http + 'api/v1/investProject/listApprovingAttachmentByPage',
    // 修改项目状态
    updateStatus: http + 'api/v1/investProject/updateStatus',
    // 修改附件状态
    updateAttachmentStatus: http + 'api/v1/investProject/updateAttachmentStatus',
    // 修改附件公开接口
    updateAttachment: http + 'api/v1/investProject/updateAttachment',
    // 收藏项目/附件
    addCollectRecord: http + 'api/v1/collectRecord/addCollectRecord',
    // 取消收藏项目/附件
    cancelCollect: http + 'api/v1/collectRecord/cancelCollect',
    // 我的收藏列表
    getMyCollectRecords: http + 'api/v1/collectRecord/getMyCollectRecords',
    // 预览项目附件
    previewAttachment: http + 'api/v1/investProject/previewAttachment',
    // 编辑项目
    updateInvestProjectInfo: `${http}api/v1/investProject/updateInvestProjectInfo`,


    // *************用户管理******************
    // 用户列表
    userListByPage: http + 'api/v1/user/listByPage',
    // 管理员编辑用户信息
    adminModify: http + 'api/v1/user/adminModify',
    // 管理员封禁和解封用户
    adminUpdateUserStatus: http + 'api/v1/user/adminUpdateUserStatus',
    // 用户注销登录
    logout: http + 'api/v1/user/user/logout',
    // 编辑用户自己的信息
    modifySelf: http + 'api/v1/user/modifySelf',
    // 用户信息
    userInfo: http + 'api/v1/user/userInfo',

     // *************角色管理******************
    // 新增角色
    roleAdd: http + 'api/v1/role/add',
    // 批量删除角色
    delROleByIds: http + 'api/v1/role/delByIds',
    // 全部角色
    roleListAll: http + 'api/v1/role/listAll',
    // 修改角色
    roleModify: http + 'api/v1/role/modify',

    // *************下载中心******************
    // 查询我的下载记录
    listMyDownloadRecord: http + 'api/v1/downloadRecord/listMyDownloadRecord',
    // 下载附件
    downloadAttachment: http + 'api/v1/downloadRecord/downloadAttachment',
    // downloadFileByKey: http + '/api/v1/file/downloadFileByKey',

    // *************钱包管理******************
    // 查询我的钱包
    getMyWallet: http + 'api/v1/wallet/getMyWallet',
    // 修改钱包状态
    updateWalletStatus: http + 'api/v1/wallet/updateStatus',


    // *************消费金币******************
    // 根据条件查询钱币消耗
    getActionCoinConfig: http + 'api/v1/ActionCoinConfig/getActionCoinConfig',


    // *************用户管理******************
    // 用户列表
    getUserList: http + 'api/v1/user/listByPage',

    
    hotAndRecommend: http + 'api/v1/investProject/hotAndRecommend',



    // *************公告中心******************
    // 公告列表
    getNoticeList: http + 'api/v1/notice/listByPage',
    addNotice: http + 'api/v1/notice/add',
    endNotice: http + 'api/v1/notice/endNotice',
    delNoticeById: http + 'api/v1/notice/delById',

    // c端
    // 上传图片
    uploadImage: `${http}api/v1/file/uploadImage`,
    // 热门推荐项目
    hotList: `${http}api/v1/open/project/hotList`,
    getUserInfo: `${http}api/v1/front/user/userInfo`,
    // 管理员将xx设置为副管理员
    appointAsAdminAssistant: `${http}api/v1/user/appointAsAdminAssistant`,
    
    // 首页
    // 项目统计
    projectStatistics: `${http}api/v1/statistic/projectStatistics`,
    // 下载统计
    downloadUploadStatistics: `${http}api/v1/statistic/downloadUploadStatistics`,
    // 用户统计,每日新增注册用户
    userStatistics: `${http}api/v1/statistic/userStatistics`,

    // 项目列表
    getProjectList: `${http}api/v1/front/investProject/listByPage`,
    // 上传项目附件
    clientUploadAttachment: `${http}api/v1/file/uploadAttachment`,
    clientInvestProjectUpload: `${http}api/v1/front/investProject/uploadAttachment`,
    clientProjectAdd: `${http}api/v1/front/investProject/add`,
    // 获取项目详情
    clientProjectDetail: `${http}api/v1/front/investProject/getInfoById`,
    // 下载文件
    downloadFileByKey: `${http}api/v1/file/downloadFileByKey`,
    // 查看联系人
    getEnterpriseContact: `${http}api/v1/front/investProject/getEnterpriseContact`,
    // 客户端预览
    clientPreviewAttachment: `${http}api/v1/front/investProject/previewAttachment`,

    // 我的
    // 钱包
     myWallet: `${http}api/v1/wallet/getMyWallet`,
    // 我的下载
    // listMyDownloadRecord: `${http}api/v1/downloadRecord/listMyDownloadRecord`
    // 我的消息
    messagtList: `${http}api/v1/message/listByPage`,

    // 客户端预览
    clientPreview: `${http}api/v1/front/investProject/previewAttachment`,

    // 删除项目
    deleteProject: `${http}api/v1/investProject/deleteProject`,
    // 删除附件
    deleteAttachment: `${http}api/v1/investProject/deleteAttachment`,
    // 项目置顶
    topProject: `${http}api/v1/investProject/topProject`,
    // 我的上传
    listMyAttachmentByPage: `${http}api/v1/front/investProject/listMyAttachmentByPage`
}