<template>
  <div id="app">
    <!-- <loading v-if="rootLoading" size="large" tip="页面正在加载中..." />
    <router-view v-else /> -->
    <router-view  />
    <clientLogin ref="clientDialog"/>
  </div>
</template>

<script>
import clientLogin from '@/components/clientLogin/index.vue'
// import HelloWorld from './components/HelloWorld.vue'
// import loading from './components/loading.vue'
import { mapMutations } from 'vuex';

export default {
  name: 'App',
  data(){
    return{
      loading: true
    }
  },
  components: {
    clientLogin
  },
  methods: {
    ...mapMutations(['setClientLoginRef'])
  },
  // computed:{
	// 		...mapState([
  //       'rootLoading'
  //     ])
  // },
  // components: {
  //   loading
  // },
  async mounted(){
    await this.$nextTick()
    this.setClientLoginRef(this.$refs.clientDialog)
    // this.$clientLogin(this.$refs.clientDialog)
  },
  beforeRouteEnter(){
    // console.log(this.$router)
  }
}
</script>

<style lang="less">
@import url('./public/reset.less');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  height: 100%;
}
</style>
