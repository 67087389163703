<template>
    <div>
        <el-form ref="formRef" :rules="formRule" :model="formData" label-width="80px">
            <div v-show="!isNext">
                <el-form-item label="姓名" prop="nickname">
                    <el-input size="large" v-model="formData.nickname" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="所在机构" prop="enterpriseName">
                    <el-input size="large" v-model="formData.enterpriseName" placeholder="请输入所在机构"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input size="large" v-model="formData.phone" maxlength="11" placeholder="请输入手机号">
                        <el-button slot="append" @click="getValidCode">发送验证码</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="validCode">
                    <el-input size="large" v-model="formData.validCode" placeholder="请输入验证码"></el-input>
                </el-form-item>
            </div>
            <div v-show="isNext">
                <el-form-item label="职位" prop="position">
                    <el-input size="large" v-model="formData.position" placeholder="请输入职位"></el-input>
                </el-form-item>
                <el-form-item label="机构背景" prop="institutionalBackgrounds">
                    <el-select v-model="formData.institutionalBackgrounds" multiple placeholder="请选择机构背景">
                        <el-option v-for="item in institution" :key="item.id" :label="item.labelName"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="关注领域" prop="focusAreas">
                    <el-select v-model="formData.focusAreas" multiple placeholder="请选择关注领域">
                        <el-option v-for="item in field" :key="item.id" :label="item.labelName"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="偏好轮次" prop="preferenceRound">
                    <el-select v-model="formData.preferenceRound" multiple placeholder="请选择偏好轮次">
                        <el-option v-for="item in round" :key="item.id" :label="item.labelName"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input size="large" v-model="formData.password" placeholder="请输入密码"></el-input>
                </el-form-item>
                <el-form-item label="名片" prop="businessCard">
                    <el-upload v-if="!formData.businessCard" class="upload-demo" action="#" :auto-upload="false"
                        :on-change="handleChange" :on-remove="handleRemove">
                        <el-button size="small" type="primary">点击上传名片</el-button>
                    </el-upload>
                    <div v-if="formData.businessCard" class="imgBox">
                        <el-image style="width: 200px; height: 100px" :src="formData.businessCard"
                            :preview-src-list="[formData.businessCard]">
                        </el-image>
                        <i class="el-icon-delete" @click="handleRemove" color="#ff9900"></i>
                    </div>
                    <!-- <uploadFile v-model="formData.businessCard"/> -->
                </el-form-item>
            </div>

        </el-form>
        <div v-if="isNext" class="bottomBtn">
            <el-button :loading="loading" @click="preBtn" style="width:30%;" size="large" type="plan">上一步</el-button>
            <el-button :loading="loading" @click="registry" style="width:30%;" size="large"
                type="primary">注册</el-button>
        </div>
        <el-button v-if="!isNext" :loading="loading" @click="nextBtn" style="width:100%;" size="large"
            type="primary">下一步</el-button>
        <el-link :underline="false" type="primary" @click="login" style="margin-top:10px;">去登录</el-link>
    </div>
</template>

<script>

import { mapMutations } from 'vuex';
export default {
    name: 'registryComponent',
    data() {
        return {
            isNext: false,
            countdown: 0, // 初始倒计时秒数
            timer: null, // 计时器
            name: '',
            password: '',
            code: '',
            identifyCode: '',
            loading: false,
            // 定义三个多选标签
            institution: [],
            field: [],
            round: [],
            formData: {
                password: '',
                nickname: "",
                enterpriseName: "",
                position: "",
                institutionalBackgrounds: [],
                focusAreas: [],
                preferenceRound: [],
                businessCard: "",
                phone: "",
                validCode: "",
            },
            // 验证规则
            formRule: {
                password: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
                nickname: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
                enterpriseName: [{ required: true, message: '所在机构不能为空', trigger: 'blur' }],
                position: [{ required: false, message: '职位不能为空', trigger: 'blur' }],
                institutionalBackgrounds: [
                    { required: true, message: '机构背景不能为空', trigger: ['blue', 'change'] },
                    {
                        type: 'array',
                        max: 2,
                        message: '最多选择2个机构背景',
                        trigger: ['blue', 'change']
                    }
                ],
                focusAreas: [{ required: true, message: '关注领域不能为空', trigger: ['blue', 'change'] },
                {
                    type: 'array',
                    max: 3,
                    message: '最多选择3个关注领域',
                    trigger: ['blue', 'change']
                }
                ],
                preferenceRound: [
                    { required: true, message: '偏好轮次不能为空', trigger: ['blue', 'change'] },
                    {
                        type: 'array',
                        max: 2,
                        message: '最多选择2个偏好轮次',
                        trigger: ['blue', 'change']
                    }
                ],
                businessCard: [{ required: true, message: '名片不能为空', trigger: 'blur' }],
                phone: [{ required: true, message: '手机号不能为空', trigger: 'blur' }],
                validCode: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
            }
            // 上传文件
            // 判断是否上传了文件
        }
    },
    mounted() {
        this.getLabelList();
    },
    methods: {
        ...mapMutations(["setUser", "setToken"]),
        preBtn() {
            // this.$refs["formRef"].clearValidate(['nickname','enterpriseName','phone','validCode'])
            this.isNext = false
        },
        nextBtn() {
            let fieldsToValidate = ['nickname', 'enterpriseName', 'phone', 'validCode'];
            Promise.all(fieldsToValidate.map((field) => {
                return new Promise((resolve) => {
                    this.$refs['formRef'].validateField(field, (errorMessage) => {
                        resolve(errorMessage); // all返回一个数组
                    });
                });
            })
            ).then((errorMessages) => {
                let valid = errorMessages.every((errorMessage) => { // every全部返回为true，结果才为true
                    return errorMessage == '';
                });
                this.isNext = valid
            });
        },
        registry() {
            this.$refs["formRef"].validate(async value => {
                if (!value) {
                    return false;
                } else {
                    let data = await this.$http.Post(this.$api.registry, this.formData);
                    if (data.code == 200) {
                        window.localStorage.setItem("token", data.data || "");
                        this.setToken(data.data);
                        this.$http.Get(this.$api.getUserInfo, {}).then((res) => {
                            window.localStorage.setItem("user", JSON.stringify(res.data));
                            this.setUser(res.data);
                            this.$emit('success', res.data)
                            this.$message.success("注册成功");
                            // 清空数据
                            this.formData = {
                                password: '',
                                nickname: "",
                                enterpriseName: "",
                                position: "",
                                institutionalBackgrounds: [],
                                focusAreas: [],
                                preferenceRound: [],
                                businessCard: "",
                                phone: "",
                                validCode: "",
                            }
                        });
                    }
                }
            })
        },
        // 去登录
        login() {
            this.$emit('login')
        },
        // 获取查询标签数据
        async getLabelList() {
            // 获取机构背景 id 4
            let data1 = await this.$http.Get(this.$api.listByGroupId, { labelGroupId: 4 })
            this.institution = data1.data

            // 获取关注领域
            let data2 = await this.$http.Get(this.$api.listByGroupId, { labelGroupId: 5 })
            this.field = data2.data

            // 获取偏好轮次
            let data3 = await this.$http.Get(this.$api.listByGroupId, { labelGroupId: 6 })
            this.round = data3.data
        },
        // 获取注册码方法
        async getValidCode() {
            if (this.formData.phone) {
                // 验证是否有手机号
                await this.$http.Get(this.$api.registrySendValidCode, { mobilePhone: this.formData.phone });
                // this.formData.validCode = data;
            } else {
                this.$message.warning("请输入手机号")
            }
        },
        // 触发上传文件
        async handleChange(file) {
            if (file) {
                let fd = new FormData();
                fd.append("file", file.raw);
                let { data } = await this.$http.Post(this.$api.uploadFile, fd);
                // this.formData.businessCard = data.fileUrl;
                this.$set(this.formData, 'businessCard', data.fileUrl)
            }
        },
        // 触发文件删除方法
        handleRemove() {
            this.formData.businessCard = "";
        },
    }
}
</script>

<style lang="less">
.registry {
  .el-select {
    width: 100%;
  }
}
.imgBox {
  display: flex;
  align-items: center;
}
.bottomBtn {
  display: flex;
  justify-content: space-between;
}
</style>