<template>
    <div>
        <el-input style="margin-bottom: 20px" size="large" v-model="name" placeholder="账号" suffix-icon="el-icon-user">
            <!-- <a-icon slot="prefix" type="user" /> -->
        </el-input>
        <el-input v-if="isPasswordLogin" style="margin-bottom: 20px" size="large" v-model="password" type="password"
            placeholder="密码" suffix-icon="el-icon-view">
        </el-input>
        <div v-else class="validCodeItem">
            <el-input style="margin-bottom: 20px" size="large" v-model="validCode" placeholder="验证码"></el-input>
            <el-button class="btn" :disabled="countdown > 0" @click="getValidCode">
                {{ countdown === 0 ? "获取验证码" : countdown + "秒后重试" }}
            </el-button>
        </div>

        <!-- <el-row type="flex" align="middle">
            <el-col flex="auto" style="padding-right:20px;box-">
            </el-col>
            <el-col flxe="180px">
            </el-col>
        </el-row> -->
        <!-- <el-row type="flex" align="middle" :gutter="16">
            <el-col :span="15">
                <el-input ref="nameInput" size="large" :maxLength="4" v-model="code" placeholder="验证码">
                </el-input>
            </el-col>
            <el-col :span="9" @click="createCode" style="cursor: pointer;">
                <identify :contentHeight="40" :contentWidth="124" :identifyCode="identifyCode" />
            </el-col>
        </el-row> -->
        <el-button :loading="loading" @click="login" style="width: 100%; margin-top: 20px" size="large"
            type="primary">登录</el-button>
        <div class="bottomItem">
            <el-button @click="changeLoginType" type="text" size="large">{{
                isPasswordLogin ? "验证码登录" : "密码登录"
                }}</el-button>
            <el-button @click="goToRegistryPage" type="text" size="large">去注册</el-button>
        </div>
    </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
    name: "loginComponent",
    data() {
        return {
            countdown: 0, // 初始倒计时秒数
            timer: null, // 计时器
            isPasswordLogin: false,
            name: "",
            password: "",
            validCode: "",
            code: "",
            identifyCode: "",
            loading: false,
        };
    },
    methods: {
        ...mapMutations(["setUser", "setToken"]),
        async login() {
            if (!this.name.trim().length) {
                this.$message.error("请输入账号");
                return;
            }
            if (this.isPasswordLogin) {
                if (!this.password.trim().length) {
                    this.$message.error("请输入密码");
                    return;
                }
                this.loading = true;
                const params = {
                    phone: this.name,
                    password: this.password,
                };
                // const res = await this.$http.Post(this.$api.login,params)
                // const loginRes = await this.$http.Get(this.$api.userInfo, {userId:''})
                // console.log('登录成功---------》',res)
                // console.log('登录成功---loginRes------》',loginRes)
                // window.localStorage.setItem('token', res.data || '')
                // window.localStorage.setItem('user', JSON.stringify(loginRes.data))
                // this.setUser(loginRes.data)
                // this.$router.replace({
                //     path: '/home'
                // })
                this.$http
                    .Post(this.$api.login, params)
                    .then((res) => {
                        if (res.code !== 200) {
                            return this.$message.error(res.msg);
                        }
                        window.localStorage.setItem("token", res.data || "");
                        this.setToken(res.data);
                        this.$http.Get(this.$api.getUserInfo, {}).then((res) => {
                            window.localStorage.setItem("user", JSON.stringify(res.data));
                            this.setUser(res.data);
                            this.$emit('success', res.data)
                        });
                    })
                    // .catch((err) => {
                    //     this.$message.error("报错了" + err.msg);
                    // })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                if (!this.isPasswordLogin && !this.validCode.trim().length) {
                    this.$message.error("请输入验证码");
                    return;
                }
                this.loading = true;
                const params = {
                    phone: this.name,
                    validCode: this.validCode,
                };
                this.$http
                    .Post(this.$api.mobileLogin, params)
                    .then((res) => {
                        if (res.code !== 200) {
                            return this.$message.error(res.msg);
                        }
                        window.localStorage.setItem("token", res.data || "");
                        this.setToken(res.data);
                        this.$http.Get(this.$api.getUserInfo, {}).then((res) => {
                            window.localStorage.setItem("user", JSON.stringify(res.data));
                            this.setUser(res.data);
                            this.$emit('success', res.data)
                            // const path = this.$route.query.callbackUrl || "/home";
                            // this.$router.replace({
                            //     path,
                            // });
                        });
                    })
                    // .catch((err) => {
                    //     this.$message.error("报错了" + err.msg);
                    // })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        goToRegistryPage() {
            this.$emit('registry')
        },
        createCode() {
            var str = "abcdefghjklmnpqrstuvwxyz";
            this.identifyCode = "";
            for (var i = 0; i < 4; i++) {
                //随机产生字符串的下标
                var n = parseInt(Math.random() * str.length);
                this.identifyCode += str[n];
            }
        },
        changeLoginType() {
            this.isPasswordLogin = !this.isPasswordLogin;
        },
        // 获取注册码方法
        async getValidCode() {
            if (this.name) {
                // 验证是否有手机号
                await this.$http.Get(this.$api.loginSendValidCode, {
                    mobilePhone: this.name,
                });
                this.startCountdown();
            } else {
                this.$message.warning("请输入手机号");
            }
        },
        startCountdown() {
            if (this.countdown > 0) return;
            this.countdown = 60; // 倒计时60秒
            this.timer = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown -= 1;
                } else {
                    clearInterval(this.timer);
                }
            }, 1000);
        },
    },
};
</script>

<style lang="less" scoped>
.validCodeItem {
    display: flex;
    .btn {
        height: 40px;
        margin-left: 8px;
    }
}
.bottomItem {
    display: flex;
    justify-content: space-between;
    padding: 0;
}
</style>