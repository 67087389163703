import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import store from './store/index.js'
import router from './router/index.js'
import VueParticles from 'vue-particles' // login粒子动画
// import { message} from 'ant-design-vue'
import api from './public/api.js'
import http from './public/axios'
import Global from './public/global.js'
import moment from 'moment'
// import './permission'
// import loginComponent from '@/components/clientLogin/index.vue'

// 创建一个单例
// const clientLogin = new Vue({
//   store,
//   render: h => h(loginComponent)
// }).$mount();

// import 'ant-design-vue/dist/antd.css';
// import { Modal } from 'ant-design-vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts'

// Vue.component(Button.name, Button);
// Vue.component(Select.name, Select);

Vue.config.productionTip = false;
// Vue.prototype.$message = message;
Vue.prototype.$http = http;
Vue.prototype.$api = api;
Vue.prototype.$echarts = echarts;
Vue.prototype.Global = Global
Vue.prototype.$moment = moment
Vue.prototype.$bus = new Vue()
// 客户端登录
// Vue.prototype.$clientLogin = clientLogin;

// Vue.use(router)
Vue.use(VueParticles);
// Vue.use(Modal)
Vue.use(ElementUI)

console.log(store)

 const app = new Vue({
  render: h => h(App),
  store,
  router
});

(() => {
  if (store.state.token) {
    http.Get(api.getUserInfo, {}).then((res) => {
      window.localStorage.setItem("user", JSON.stringify(res.data));
      store.commit('setUser', res.data);
      app.$mount('#app');
    });
  } else {
    app.$mount('#app');
  }
})();

